const Layout = () =>import('@/layout')
const parentLayout = () =>import('@/layout/parentLayout')

export const asyncRoutes = [
  {
    path: '/config',
    component: Layout,
    name: 'config',
    meta: { title: '配置管理', icon: 'company', authority: ""  },
    children: [
      {
        path: 'slider',
        name: 'slider',
        component: parentLayout,
        meta: { title: '轮播图管理', icon: 'children_menu', authority: "", isHidden: true  },
        children: [
          {
            path: '/config/slider/list',
            name: 'sliderList',
            component: () => import('@/views/config/slider'),
            meta: { title: '轮播图', icon: 'children_menu', authority: "", secName: "sliderList"},
          },
          {
            path: '/config/slider/add',
            name: 'sliderAdd',
            component: () => import('@/views/config/slider/edit'),
            meta: { title: '新增轮播图', icon: 'children_menu', authority: "", secName: "sliderList"},
            hidden: true
          },
          {
            path: '/config/slider/edit',
            name: 'sliderEdit',
            component: () => import('@/views/config/slider/edit'),
            meta: { title: '编辑轮播图', icon: 'children_menu', authority: "", secName: "sliderList"},
            hidden: true
          },
        ]
      },
      {
        path: 'menu',
        name: 'menu',
        component: parentLayout,
        meta: { title: '菜单管理', icon: 'children_menu', authority: "", isHidden: true  },
        children: [
          {
            path: '/config/menu/list',
            name: 'menuList',
            component: () => import('@/views/config/menu'),
            meta: { title: '菜单', icon: 'children_menu', authority: "", secName: "menuList"},
          },
          {
            path: '/config/menu/add',
            name: 'menuAdd',
            component: () => import('@/views/config/menu/edit'),
            meta: { title: '新增菜单', icon: 'children_menu', authority: "", secName: "menuList"},
            hidden: true
          },
          {
            path: '/config/menu/edit',
            name: 'menuEdit',
            component: () => import('@/views/config/menu/edit'),
            meta: { title: '编辑菜单', icon: 'children_menu', authority: "", secName: "menuList"},
            hidden: true
          },
        ]
      },
      {
        path: 'notice',
        name: 'notice',
        component: parentLayout,
        meta: { title: '公告管理', icon: 'children_menu', authority: "", isHidden: true  },
        children: [
          {
            path: '/config/notice/list',
            name: 'noticeList',
            component: () => import('@/views/config/notice'),
            meta: { title: '公告', icon: 'children_menu', authority: "", secName: "noticeList"},
          },
          {
            path: '/config/notice/add',
            name: 'noticeAdd',
            component: () => import('@/views/config/notice/edit'),
            meta: { title: '添加公告', icon: 'children_menu', authority: "", secName: "noticeList"},
            hidden: true
          },
          {
            path: '/config/notice/edit',
            name: 'noticeEdit',
            component: () => import('@/views/config/notice/edit'),
            meta: { title: '编辑公告', icon: 'children_menu', authority: "", secName: "noticeList"},
            hidden: true
          },
        ]
      },
      {
        path: 'other',
        name: 'other',
        component: () => import('@/views/config/other'),
        meta: { title: '其他', icon: 'children_menu', authority: ""  },
      },
    ]
  },
  {
    path: '/content',
    component: Layout,
    name: 'content',
    meta: { title: '内容管理', icon: 'company', authority: ""  },
    children: [
      {
        path: 'category',
        name: 'category',
        component: parentLayout,
        meta: { title: '分类管理', icon: 'children_menu', authority: "", isHidden: true  },
        children: [
          {
            path: '/content/category/list',
            name: 'categoryList',
            component: () => import('@/views/content/category'),
            meta: { title: '分类', icon: 'children_menu', authority: "", secName: "categoryList"},
          },
          {
            path: '/content/category/add',
            name: 'categoryAdd',
            component: () => import('@/views/content/category/edit'),
            meta: { title: '新增分类', icon: 'children_menu', authority: "", secName: "categoryList"},
            hidden: true
          },
          {
            path: '/content/category/edit',
            name: 'categoryEdit',
            component: () => import('@/views/content/category/edit'),
            meta: { title: '编辑分类', icon: 'children_menu', authority: "", secName: "categoryList"},
            hidden: true
          },
        ]
      },
      {
        path: 'article',
        name: 'article',
        component: parentLayout,
        meta: { title: '资讯管理', icon: 'children_menu', authority: "", isHidden: true  },
        children: [
          {
            path: '/content/article/list',
            name: 'articleList',
            component: () => import('@/views/content/article'),
            meta: { title: '资讯', icon: 'children_menu', authority: "", secName: "articleList"},
          },
          {
            path: '/content/article/add',
            name: 'articleAdd',
            component: () => import('@/views/content/article/edit'),
            meta: { title: '新增资讯', icon: 'children_menu', authority: "", secName: "articleList"},
            hidden: true
          },
          {
            path: '/content/article/edit',
            name: 'articleEdit',
            component: () => import('@/views/content/article/edit'),
            meta: { title: '编辑资讯', icon: 'children_menu', authority: "", secName: "articleList"},
            hidden: true
          },
        ]
      },
      {
        path: 'shopTemplate',
        name: 'shopTemplate',
        component: parentLayout,
        meta: { title: '样板门店', icon: 'children_menu', authority: "", isHidden: true  },
        children: [
          {
            path: '/content/shopTemplate/list',
            name: 'shopTemplateList',
            component: () => import('@/views/content/shopTemplate'),
            meta: { title: '样板门店', icon: 'children_menu', authority: "", secName: "shopTemplateList"},
          },
          {
            path: '/content/shopTemplate/add',
            name: 'shopTemplateAdd',
            component: () => import('@/views/content/shopTemplate/edit'),
            meta: { title: '新增样板门店', icon: 'children_menu', authority: "", secName: "shopTemplateList"},
            hidden: true
          },
          {
            path: '/content/shopTemplate/edit',
            name: 'shopTemplateEdit',
            component: () => import('@/views/content/shopTemplate/edit'),
            meta: { title: '编辑样板门店', icon: 'children_menu', authority: "", secName: "shopTemplateList"},
            hidden: true
          },
        ]
      },
    ]
  },
  {
    path: '/userManage',
    component: Layout,
    name: 'userManage',
    meta: { title: '用户管理', icon: 'company', authority: ""  },
    children: [
      {
        path: 'customer',
        name: 'customer',
        component: parentLayout,
        meta: { title: '用户管理', icon: 'children_menu', authority: "", isHidden: true  },
        children: [
          {
            path: '/userManage/customer/list',
            name: 'customerList',
            component: () => import('@/views/user/customer'),
            meta: { title: '用户列表', icon: 'children_menu', authority: "", secName: "customerList"},
          },
          {
            path: '/userManage/customer/add',
            name: 'customerAdd',
            component: () => import('@/views/user/customer/save'),
            meta: { title: '添加用户', icon: 'children_menu', authority: "", secName: "customerList"},
            hidden: true
          },
          {
            path: '/userManage/customer/detail',
            name: 'customerDetail',
            component: () => import('@/views/user/customer/save'),
            meta: { title: '用户详情', icon: 'children_menu', authority: "", secName: "customerList"},
            hidden: true
          },
          {
            path: '/userManage/customer/bank',
            name: 'customerBank',
            component: () => import('@/views/user/customer/bank.vue'),
            meta: { title: '用户账户', icon: 'children_menu', authority: "", secName: "customerList"},
            hidden: true
          },
        ]
      },
      {
        path: 'partner',
        name: 'partner',
        component: parentLayout,
        meta: { title: '合伙人管理', icon: 'children_menu', authority: "", isHidden: true  },
        children: [
          {
            path: '/userManage/partner/list',
            name: 'partnerList',
            component: () => import('@/views/user/partner'),
            meta: { title: '合伙人列表', icon: 'children_menu', authority: "", secName: "partnerList"},
          },
          {
            path: '/userManage/partner/detail',
            name: 'partnerDetail',
            component: () => import('@/views/user/partner/detail'),
            meta: { title: '合伙人详情', icon: 'children_menu', authority: "", secName: "partnerList"},
            hidden: true
          },
          {
            path: '/userManage/partner/add',
            name: 'partnerAdd',
            component: () => import('@/views/user/partner/save'),
            meta: { title: '添加合伙人', icon: 'children_menu', authority: "", secName: "partnerList"},
            hidden: true
          },
        ]
      },
    ]
  },
  {
    path: '/shopManage',
    component: Layout,
    name: 'shopManage',
    meta: { title: '门店管理', icon: 'company', authority: ""  },
    children: [
      {
        path: '/shopManage/shop/list',
        name: 'shopList',
        component: () => import('@/views/shop'),
        meta: { title: '门店列表', icon: 'children_menu', authority: "", secName: "shopList"},
      },
      {
        path: '/shopManage/partner/list',
        name: 'shopPartnerList',
        component: () => import('@/views/shop/partner'),
        meta: { title: '股东列表', icon: 'children_menu', authority: "", secName: "shopList"},
        hidden: true
      },
      {
        path: '/shopManage/shop/detail',
        name: 'shopDetail',
        component: () => import('@/views/shop/save'),
        meta: { title: '门店详情', icon: 'children_menu', authority: "", secName: "shopList"},
        hidden: true
      },
    ]
  }
]
