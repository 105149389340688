import request from "@/utils/requests"

function getArea(url){
    return request.request({
        url: url ? url : "/area/list",
        method: "get",
    })
}


function settingUpdate(data){
    return request.request({
        url: "/setting/update",
        method: "post",
        data
    })
}

function setting(){
    return request.request({
        url: "/setting/index",
        method: "get",
    })
}

function uploadFile(file, abort, url){

    let formData = new FormData();
    formData.append("file", file.file);

    let post = {
        url: url ? url : "/upload/file",
        method: "post",
        data: formData,
        headers: {
            "Content-Type": "multipart/form-data"
        }
    }

    if(abort){
        post.signal = abort.signal
    }

    return request.request(post)
}


function uploadFiles(file, abort){

    let formData = new FormData();
    formData.append("file", file.file);

    let post = {
        url: "/upload/files",
        method: "post",
        data: formData,
        headers: {
            "Content-Type": "multipart/form-data"
        }
    }

    if(abort){
        post.signal = abort.signal
    }

    return request.request(post)
}

export default {
    getArea,
    uploadFile,
    uploadFiles,
    setting,
    settingUpdate
}
