import Vue from 'vue'
import VueRouter from 'vue-router'
const Layout = () =>import('@/layout')

Vue.use(VueRouter)

export const routes = [
  {
    path: '/login',
    name: "Login",
    component: ()=>import("@/views/login"),
    meta: { title: '登陆'},
    hidden: true
  },
  {
    path: '/',
    name: "home",
    component: Layout,
    redirect: '/dashboard',
    meta: { title: '首页', icon: 'home_icon', keepAlive: false,  },
    hidden: false,
    children: [
      {
        path: '/dashboard',
        name: 'dashboard',
        component: () => import('@/views/home'),
        meta: {
          title: '首页',
          icon: 'children_menu',
        },
      },
    ]
  },
]

const createRouter = () => new VueRouter({
  mode: "hash",
  base: "/ops/",
  routes,
})

const router = createRouter()
export function resetRouter() {
  const newRouter = createRouter()
  router.matcher = newRouter.matcher // 新路由实例matcer，赋值给旧路由实例的matcher，（相当于replaceRouter）
}

export default router
