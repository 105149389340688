import requests from "/src/utils/requests"
import role from "./role"
import permission from "./permission"
import sensitive from "./sensitive"

const getUserList = function ({pageNum = 1, pageSize = 10, username, mobile, status}){
    return requests({
        url: `/user/page`,
        method: "post",
        data: {
            pageNum,
            pageSize,
            username,
            mobile,
            status,
        }
    })
}

const getUserInfo = function (){

    // return Promise.resolve({
    //     code: 0,
    //     data: {
    //         roles: {
    //             menus: []
    //         },
    //     }
    // })
    return requests({
        url: "/user/profile",
        method: "get",
    })
}

const login = ({ username, password, uuid, code })=> {
    return requests({
        url: "/user/login",
        method: "post",
        data: { username, password, uuid, code }
    })
}

const addUser = function ({username, mobile, account}){
    return requests({
        url: `/user`,
        method: "post",
        data: {
            username,
            mobile,
            account
        }
    })
}

const editUser = function ({id, username, mobile, status, password}){

    return requests({
        url: `/user`,
        method: "put",
        data: {
            id,
            username,
            mobile,
            status,
            password
        }
    })
}


const getUserDetail = function ({id}){
    return requests({
        url: `/user/userDetail`,
        method: "get",
        params: {
            id
        }
    })
}

const generateAccount = function ({mobile, tenantCode}){
    return requests({
        url: `/user/generateAccount`,
        method: "get",
        params: {
            mobile,
            tenantCode
        }
    })
}

const resetPassword = function ({id ,mobile}){
    return requests({
        url: `/user/resetPassword`,
        method: "put",
        data: {
            id,
            mobile,
        }
    })
}

const verifyPassword = function ({id , password}){
    return requests({
        url: `/user/verifyPassword`,
        method: "post",
        data: {
            id,
            password,
        }
    })
}

const logout = ()=> {
    return requests({
        url: "/user/logout",
        method: "get",
        params: {}
    })
}


const createCustomer = function (data){
    return requests({
        url: `/user/create`,
        method: "post",
        data
    })
}

export default {
    getUserInfo,
    login,
    getUserList,
    addUser,
    editUser,
    getUserDetail,
    generateAccount,
    resetPassword,
    verifyPassword,
    ...role,
    ...permission,
    ...sensitive,
    logout,
    createCustomer
}
