import request from "@/utils/requests"

function pageCustomer({page = 1, pageSize = 10, mobile, start, end, is_referee}){
    return request.request({
        url: "/customer/list",
        method: "get",
        params: {
            page,
            pageSize, start, end, is_referee, mobile
        }
    })
}

function pageCustomerBank({page = 1, pageSize = 10, customer_id}){
    return request.request({
        url: "/user/bank",
        method: "get",
        params: {
            page,
            pageSize, customer_id
        }
    })
}


function setRefereeCustomer({id, is_referee}){
    return request.request({
        url: "/customer/referee",
        method: "put",
        params: {
            id,
            is_referee,
        }
    })
}

export default {
    pageCustomer,
    setRefereeCustomer,
    pageCustomerBank

}
